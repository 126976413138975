import {
  Box,
  Button,
  Container,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import CreateProject from "../components/forms/CreateProject";
import { ModalContext } from "../context/ModalContext";
import Modal from "../components/Modal";
import { useProjects } from "../hooks/useProjects";
import { IProject } from "../models/project";
import ProjectsList from "../components/ProjectsList";
import GroupIcon from "@mui/icons-material/Group";
import { Link } from "react-router-dom";
import { getPartProjects } from "../services/project-service";
import { getCurrentUser } from "../services/auth-service/auth-service";

export default function MainPage() {
  const { projects, addProject, deleteProject } = useProjects();
  const { open } = useContext(ModalContext);
  const [partProjects, setPartProjects] = useState<IProject[]>([]);

  const createHandler = (project: IProject) => {
    addProject(project);
  };

  const deleteHandler = (project: IProject) => {
    deleteProject(project);
    setPartProjects(partProjects.filter((p) => p.id !== project.id));
  };

  useEffect(() => {
    const fetchPartProjects = async () => {
      var user = getCurrentUser();
      var response = await getPartProjects(user?.id);
      setPartProjects(response.data);
    };
    fetchPartProjects();
  }, [projects, partProjects]);

  return (
    <>
      <Container sx={{ mt: 4, mb: 6 }}>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-around"
          alignItems="center"
        >
          <Stack
            direction="row"
            spacing={2}
            justifyContent="left"
            sx={{ width: "70%" }}
          >
            <Typography variant="h4" gutterBottom sx={{ fontWeight: "500" }}>
              Мои проекты
            </Typography>
            <Button
              variant="contained"
              disableElevation={true}
              onClick={open}
              sx={{
                bgcolor: "secondary.main",
                color: "white",
                fontWeight: "700",
                fontSize: "25px",
                borderRadius: "20px",
                width: "10px",
                height: "40px",
              }}
            >
              +
            </Button>
          </Stack>
          <Box>
            <Stack direction="row" alignItems="center">
              <GroupIcon sx={{ mr: 1 }} />
              <Typography variant="h6" sx={{}}>
                Участвую в проектах:
              </Typography>
            </Stack>
          </Box>
        </Stack>
        <Divider />
        <Stack direction="row">
          <ProjectsList projects={projects} deleteProject={deleteHandler} />
          <Box sx={{ ml: 25, mt: 4 }}>
            {partProjects.length > 0 ? (
              partProjects.map((project) => (
                <Box key={project.id}>
                  <Typography sx={{ fontSize: "20px", fontWeight: "500" }}>
                    ▶{" "}
                    <Link
                      to={`/projects/${project.id}`}
                      style={{ textDecoration: "none" }}
                    >
                      <Typography
                        sx={{
                          display: "inline-block",
                          color: "primary.main",
                          fontSize: "20px",
                          fontWeight: '500'
                        }}
                      >
                        {project.name}
                      </Typography>
                    </Link>
                  </Typography>
                  <Divider></Divider>
                </Box>
              ))
            ) : (
              <Typography sx={{ fontSize: "20px", fontWeight: "500" }}>
                Нет проектов 🤷‍♂️
              </Typography>
            )}
          </Box>
        </Stack>
      </Container>

      <Modal title="Создание проекта">
        <CreateProject onCreate={createHandler} />
      </Modal>
    </>
  );
}
