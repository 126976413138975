import { Box, Typography, TextField, Button, Grid } from "@mui/material";
import { useForm, useFormState, SubmitHandler, Controller } from "react-hook-form";
import { deleteUser } from "../../services/admin-service";

interface IDeleteUserProps {
    name?: string;
    close: () => void;
    onDelete: (name: string) => void;
}

const DeleteUser: React.FC<IDeleteUserProps> = (props) => {
    
    const { handleSubmit, control } = useForm<IDeleteUserProps>({
        mode: "onChange"
    });

    const { errors } = useFormState({
        control
    });

    const onDelete: SubmitHandler<IDeleteUserProps> = async (data) => {
        await deleteUser(data.name!)
        props.onDelete(data.name!)
    }

    return (
        <Grid container>

            <Box
                component='div'
                sx={{
                    p: '20px',
                }}
            >
                <Typography variant="h4" component="div">
                    Удаление
                </Typography>
                <Typography
                    variant="subtitle1"
                    component="div"
                    gutterBottom={true}
                    sx={{
                        color: '#b4b8c1'
                    }}
                >
                    Пользователя
                </Typography>
                <form style={{ maxWidth: '360px' }} onSubmit={handleSubmit(onDelete)}>
                    <Controller
                        control={control}
                        name="name"                       
                        render={({ field }) => (
                            <TextField
                                label="Имя"
                                size="small"
                                margin="normal"                                                                                
                                fullWidth={true}
                                onChange={(e) => field.onChange(e)}
                                value={field.value}
                                error={!!errors.name?.message}
                                helperText={errors.name?.message}
                                InputLabelProps={{ shrink: true }}
                            />
                        )}
                    />                   
                    <Button
                        onClick={props.close}                   
                        variant="contained"
                        disableElevation={true}
                        sx={{
                            mt: 2,
                            
                            width: '40%',
                            color: 'white',
                            bgcolor: 'gray'
                        }}
                    >
                        Назад
                    </Button>
                    <Button
                        onClick={props.close}           
                        type="submit"
                        variant="contained"
                        disableElevation={true}
                        sx={{
                            marginTop: 2,
                            width: '50%',
                            ml: 4,
                        }}
                        color='error'
                    >
                        Удалить
                    </Button>
                </form>
            </Box>
        </Grid>
    )
}
export default DeleteUser;