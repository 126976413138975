import { Box, Stack, Typography } from "@mui/material";
import { Link, Navigate } from "react-router-dom";
import { getCurrentUser } from "../services/auth-service/auth-service";
import stopMan from "../images/stop-man.jpg";

interface RequireAuthProps {
    children: JSX.Element
    isAdmin?: boolean
}

const RequireAuth = ({children, isAdmin}: RequireAuthProps) => {
    const user = getCurrentUser();
  
    if(user === null) {
        return <Navigate to='/auth/login'/>
    }

    if(isAdmin && user.role !== 'Admin') {
        return(
            <Stack direction='column' alignItems='center' sx={{mt: 4}}>
                <Typography variant='h5'> У вас нет прав для просмотра этой страницы</Typography>
                <Typography sx={{mt:2}}>Вернуться <Link to='/'>на главную страницу</Link></Typography>
                <Box component='img' src={stopMan} sx={{height: '440px'}}></Box>
            </Stack>
        )
    }
        
    return children;
}

export default RequireAuth;
