import axios from "axios";
import { IProject } from "../models/project";
import authHeader from "./auth-service/auth-header";
import { domain } from "./domain";

const API_URL = "https://" + domain + "/api/1.0/";

export const addProject = async (name?: string, userId?: string) => {
  return await axios.post<IProject>(API_URL + `projects`, {
    name, userId
  }, { headers: authHeader() });
};

export const deleteProject = async (id?: string) => {
  return await axios.delete(API_URL + `projects/${id}`, {
    headers: authHeader()
  });
};

export const getProjects = async (userId?: string) => {
  return await axios.get(API_URL + `projects`, { headers: authHeader(), params: {userId: userId} });
};

export const getPartProjects = async (partnerId?: string) => {
  return await axios.get<IProject[]>(API_URL + `partProjects`, { headers: authHeader(), params: {partnerId: partnerId} });
};

export const getProject = async (id?: string) => {
  return await axios.get<IProject>(API_URL + `projects/${id}`, { headers: authHeader() })
};


