import {
  Alert,
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Link,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ConfigurationItem from "../components/items/ConfigurationItem";
import CreateState from "../components/forms/CreateState";
import Modal from "../components/Modal";
import { ModalContext } from "../context/ModalContext";
import ClearIcon from "@mui/icons-material/Clear";
import { IState } from "../models/state";
import { IProject } from "../models/project";
import { deleteState, getStatesByProject } from "../services/state-service";
import { getProject } from "../services/project-service";
import { IConfig } from "../models/config";
import DeleteAccessForm from "../components/DeleteAccessForm";

export default function ProjectPage() {
  const [project, setProject] = useState<IProject | null>(null);
  const [states, setStates] = useState<IState[]>([]);
  const [state, setState] = useState<IState | null>(null);
  const [config, setConfig] = useState<IConfig>();
  const [deleteAccessForm, setAccessDeleteForm] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  const [stateId, setStateId] = useState("");

  const { open } = useContext(ModalContext);
  const [form, setForm] = useState(false);

  const params = useParams();
  const navigate = useNavigate();

  const showConfig = (config: IConfig, stateId: string) => {
    setConfig(config);
    setStateId(stateId);
    open();
  };

  const createStateHandler = (state: IState) => {
    setStates((prev) => [...prev, state]);
  };
  const deleteStateHandler = async (id: string) => {
    await deleteState(id);
    setStates(states.filter((s) => s.id !== id));
    setAccessDeleteForm(false);
  };

  const setClosedAccessForm = () => {
    setAccessDeleteForm(false);
  };

  const openAccessDeleteForm = (state: IState) => {
    setState(state);
    setAccessDeleteForm(true);
  };

  useEffect(() => {
    async function getCurrentProject() {
      const response = await getProject(params.projectId);
      setProject(response.data);
    }
    async function getCurrentStates() {
      const response = await getStatesByProject(params.projectId!);
      setStates(response.data);
    }
    getCurrentProject();
    getCurrentStates();
  }, [config, params.projectId]);

  const setCurrentConfig = (config: IConfig) => {
    setConfig(config);
  };

  const copyBtnHandle = (stateId: string) => {
    navigator.clipboard.writeText(stateId);
    setOpenAlert(true);
  };

  return (
    <>
      <Container sx={{ mt: 4 }}>
        <Typography variant="h4" gutterBottom sx={{ fontWeight: "500" }}>
          {project?.name}
        </Typography>
        <Divider />
        <Stack
          direction="row"
          spacing={2}
          justifyContent="left"
          alignItems="center"
          sx={{ width: "100%", mt: 2 }}
        >
          <Typography variant="h5">Состояния</Typography>
          <Button
            variant="contained"
            size="small"
            disableElevation={true}
            onClick={() => setForm(true)}
            sx={{
              bgcolor: "primary.main",
              color: "white",
              fontWeight: "700",
              fontSize: "10px",
              borderRadius: "10px",
              height: "25px",
            }}
          >
            Добавить
          </Button>
        </Stack>
        <Dialog
          open={form}
          onClose={() => setForm(false)}
          aria-labelledby="dialog-title"
          aria-describedby="dialog-description"
        >
          <DialogTitle id="dialog-title">Добавление состояния</DialogTitle>
          <DialogContent>
            <CreateState
              close={() => setForm(false)}
              onCreate={createStateHandler}
              projectId={project?.id}
            />
          </DialogContent>
        </Dialog>
        <Table size="small" sx={{ mb: 3, mt: 1 }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontSize: "17px" }}>Название</TableCell>
              <TableCell align="center" sx={{ fontSize: "17px" }}>
                Дата создания
              </TableCell>
              <TableCell align="center" sx={{ fontSize: "17px" }}>
                Конфигурация
              </TableCell>
              <TableCell align="right" sx={{ fontSize: "17px" }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {states.map((state) => (
              <TableRow key={state.id}>
                <TableCell sx={{ fontSize: "17px" }}>{state.name}</TableCell>
                <TableCell align="center" sx={{ fontSize: "17px" }}>
                  {new Date(state.createDate).toLocaleString()}
                </TableCell>
                <TableCell align="center" sx={{ fontSize: "17px" }}>
                  <Button
                    variant="contained"
                    size="small"
                    sx={{
                      bgcolor: "secondary.main",
                      color: "white",
                      fontSize: "12px",
                    }}
                    onClick={() => showConfig(state.configuration, state.id)}
                  >
                    Открыть
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    sx={{
                      bgcolor: "gray",
                      color: "white",
                      fontSize: "10px",
                      ml: 1,
                    }}
                    onClick={() => copyBtnHandle(state.id)}
                  >
                    Получить id
                  </Button>
                </TableCell>
                <TableCell align="right">
                  <IconButton
                    size="small"
                    sx={{
                      bgcolor: "error.main",
                      color: "white",
                      fontSize: "10px",
                      ":hover": { bgcolor: "darkred" },
                    }}
                    onClick={() => openAccessDeleteForm(state)}
                  >
                    <ClearIcon fontSize="small" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Modal
          title="Конфигурация"
          fullScreen={true}
          bgColor="#1E1E1E"
          contentColor="#D59C83"
          titleColor="white"
          titleTextAlign="center"
          closeColor="lightgray"
        >
          <ConfigurationItem
            configuration={config!}
            stateId={stateId}
            projectId={project?.id}
            setConfig={setCurrentConfig}
          />
        </Modal>
        <DeleteAccessForm
          isOpen={deleteAccessForm}
          delete={deleteStateHandler}
          setClosed={setClosedAccessForm}
          stateId={state?.id}
        />

        <Divider sx={{ borderWidth: "3px", mt: 7 }} />

        <Box sx={{ mt: 7 }}>
          <Typography variant="h5">Участники проекта</Typography>
          <Table size="small" sx={{ mb: 3, mt: 3 }}>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontSize: "17px" }}>Имя</TableCell>
                <TableCell align="center" sx={{ fontSize: "17px" }}>
                  Роль
                </TableCell>
                <TableCell align="right" sx={{ fontSize: "17px" }}>
                  Доступ
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {project?.partners.map((user) => (
                <TableRow key={user.id}>
                  <TableCell sx={{ fontSize: "17px" }}>{user.name}</TableCell>
                  <TableCell align="center" sx={{ fontSize: "17px" }}>
                    {user.role}
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{ fontSize: "17px" }}
                  ></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
        <Link
          color="primary"
          onClick={() => navigate("/")}
          sx={{ cursor: "pointer" }}
        >
          Вернуться на главную
        </Link>
      </Container>
      <Snackbar
        open={openAlert}
        autoHideDuration={3000}
        onClose={() => setOpenAlert(false)}
      >
        <Alert
          onClose={() => setOpenAlert(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          State id получен и скопирован!
        </Alert>
      </Snackbar>
    </>
  );
}
