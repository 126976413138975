import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { createConfig, updateConfig } from "../../services/state-service";
import SaveIcon from "@mui/icons-material/Save";
import UpdateIcon from "@mui/icons-material/Update";
import UploadIcon from "@mui/icons-material/Upload";
import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { IConfig } from "../../models/config";
import { ModalContext } from "../../context/ModalContext";
import { IError } from "../../models/error";

interface IConfigurationProps {
  configuration: IConfig;
  stateId: string;
  projectId: string | undefined;
  setConfig: (config: IConfig) => void;
}

const ConfigurationItem: React.FC<IConfigurationProps> = ({
  configuration,
  stateId,
  projectId,
  setConfig,
}: IConfigurationProps) => {
  const [selectedFile, setSelectedFile] = useState(new File([], ""));
  const [copyText, setCopyText] = useState("Копировать");
  const [update, setUpdate] = useState(false);
  const [prevConfig, setPrevConfig] = useState<IConfig>(configuration);
  const [error, setError] = useState<IError | null>(null);

  const { close } = useContext(ModalContext);

  const copyBtnHandle = () => {
    navigator.clipboard.writeText(
      JSON.stringify(configuration?.properties, null, 3).toString()
    );
    setCopyText("Скопировано");
  };

  const handleChange = async (event: React.ChangeEvent) => {
    const target = event.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];
    setSelectedFile(file);
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("file", selectedFile);
    await createConfig(formData, stateId)
      .then((response) => setConfig(response.data))
      .catch((error) => {
        setError(error.response.data);
      });
  };

  const onUpdate = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("file", selectedFile);
    const response = await updateConfig(formData, stateId);
    setPrevConfig(configuration);
    setConfig(response.data);
  };

  return update ? (
    <Box component="div" sx={{ mt: 3 }}>
      <Stack direction="row" spacing={2} justifyContent="space-evenly">
        <Box component="div">
          <Typography
            variant="h6"
            sx={{ color: "lightgray", textDecoration: "underline" }}
          >
            Было:{" "}
          </Typography>
          <pre>{JSON.stringify(prevConfig?.properties, null, 3)}</pre>
        </Box>
        <Box component="div" sx={{ ml: 2 }}>
          <ArrowForwardIcon htmlColor="white" fontSize="large" sx={{ mt: 9 }} />
        </Box>
        <Box component="div" sx={{ ml: 2 }}>
          <Typography
            variant="h6"
            sx={{ color: "lightgray", textDecoration: "underline" }}
          >
            Стало:{" "}
          </Typography>
          <pre>{JSON.stringify(configuration?.properties, null, 3)}</pre>
        </Box>
      </Stack>

      <form onSubmit={onUpdate}>
        <Stack
          direction="row"
          spacing={2}
          sx={{
            height: "75%",
            alignItems: "flex-end",
            justifyContent: "right",
          }}
        >
          <TextField
            type="file"
            inputProps={{ accept: "application/json,.csv" }}
            variant="standard"
            sx={{ mt: 4, bgcolor: "gray" }}
            onChange={handleChange}
          />
          <Button
            type="submit"
            startIcon={<UploadIcon />}
            variant="contained"
            size="small"
            disableElevation={true}
            sx={{
              mt: 5,
              bgcolor: "darkgreen",
              color: "white",
              width: "30%",
            }}
          >
            Загрузить
          </Button>
          <Button
            type="submit"
            startIcon={<CloseIcon />}
            variant="contained"
            size="small"
            onClick={close}
            disableElevation={true}
            sx={{
              mt: 5,
              bgcolor: "error.main",
              color: "white",
              width: "25%",
            }}
          >
            Закрыть
          </Button>
        </Stack>
      </form>
    </Box>
  ) : (
    <Box component="div" sx={{ mt: 1, maxWidth: '100%', maxHeight: '100%' }}>
      <pre>
        {configuration?.properties ? (
          <Box component="div">
            {JSON.stringify(configuration?.properties, null, 2)}
            <Stack
              direction="row"
              spacing={2}
              sx={{
                height: "75%",
                alignItems: "flex-end",
                justifyContent: "center",
              }}
            >
              <Button
                startIcon={<UpdateIcon />}
                variant="contained"
                size="small"
                disableElevation={true}
                onClick={() => setUpdate(true)}
                sx={{
                  mt: 5,
                  bgcolor: "darkgreen",
                  color: "white",
                  width: "120px",
                }}
              >
                Заменить
              </Button>
              <Button
                startIcon={<SaveIcon />}
                variant="contained"
                size="small"
                type="submit"
                onClick={copyBtnHandle}
                disableElevation={true}
                sx={{
                  mt: 5,
                  ml: 4,
                  bgcolor: "gray",
                  color: "white",
                }}
              >
                {copyText}
              </Button>
            </Stack>
          </Box>
        ) : (
          <Box component="div" textAlign="center">
            Конфиг не найден
            <form onSubmit={onSubmit}>
              <Stack
                direction="row"
                spacing={2}
                sx={{
                  height: "75%",
                  alignItems: "flex-end",
                  justifyContent: "right",
                }}
              >
                <TextField
                  type="file"
                  inputProps={{ accept: "application/json,.csv" }}
                  variant="standard"
                  sx={{ mt: 4, bgcolor: "gray" }}
                  onChange={handleChange}
                />
                <Button
                  type="submit"
                  startIcon={<UploadIcon />}
                  variant="contained"
                  size="small"
                  disableElevation={true}
                  sx={{
                    mt: 5,
                    bgcolor: "darkgreen",
                    color: "white",
                    width: "30%",
                  }}
                >
                  Загрузить
                </Button>
              </Stack>
            </form>
            <Typography sx={{ color: "error.main", mt: 2, fontSize: "14px" }}>
              {error && <p>Неверный формат файла</p>}
            </Typography>
          </Box>
        )}
      </pre>
    </Box>
  );
};
export default ConfigurationItem;
