import { Box, Typography } from '@mui/material'
import { IProject } from '../models/project'
import ProjectItem from './items/ProjectItem'

interface ProjectsListProps {
    projects: IProject[]
    deleteProject: (project: IProject) => void
}

const ProjectsList: React.FC<ProjectsListProps> = (props) => {

    return (
        <Box component='div' sx={{ mt: 3, width: '60%' }}>
            {props.projects.length === 0
                ? <Typography variant='body1' sx={{ ml: 2 }}>Проектов нет</Typography>
                : props.projects.map(project => <ProjectItem project={project} key={project.id} delete={props.deleteProject}/>)}
        </Box>
    )
}
export default ProjectsList