import { Home, Settings, AdminPanelSettings } from '@mui/icons-material';
import { Box, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import React from 'react'
import { getCurrentUser } from '../../services/auth-service/auth-service';

const listItems = [
    {
        listIcon: <Home />,
        listText: "Мои проекты",
        path: "/"
    },
    {
        listIcon: <Settings />,
        listText: "Настройки",
        path: "/settings"
    },
    {
        listIcon: <AdminPanelSettings />,
        listText: "Админ-панель",
        path: "/admin"
    },
];

interface SideListProps {
    goTo: (path: string) => void
}

const SideList: React.FC<SideListProps> = (props) => {
    
    const user = getCurrentUser();
    if(user?.role !== 'Admin')
        delete listItems[2]
    
    return (
        <Box component="div" sx={{ width: 250, bgcolor: 'secondary.main', height: '100%', color: 'white' }}>
            <List>
                {listItems.map((listItem, index) => (
                    <ListItemButton key={index} sx={{ color: 'white' }} onClick={() => props.goTo(listItem.path)}>
                        <ListItemIcon color="primary">
                            {listItem.listIcon}
                        </ListItemIcon>
                        <ListItemText primary={listItem.listText} primaryTypographyProps={{ fontSize: '18px' }} />
                    </ListItemButton>
                ))}
            </List>
        </Box>
    )
}
export default SideList;