import { Box, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import notFoundImg from "../images/notFoundImg.png";

export default function NotFoundPage() {
  return (
    <Stack
      direction="column"
      spacing={2}
      justifyContent="center"
      alignItems="center"
      sx={{ height: "60vh" }}
    >
      <Box component="img" src={notFoundImg} sx={{ width: "300px", mb: 2 }}></Box>
      <Stack direction="row" alignItems='center'>
        <Typography variant="h4">Not Found 404</Typography>
        <Link
          to="/"
          style={{
            backgroundColor: "secondary.main",
            color: "white",
            textDecoration: "none",
            marginLeft: "20px",
            padding: "10px 5px",
            border: '1px solid gray',
            borderRadius: '10px'
          }}
        >
          На главную
        </Link>
      </Stack>
    </Stack>
  );
}
