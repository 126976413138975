import { Dialog, DialogTitle, DialogActions, Button } from "@mui/material";
import { IProject } from "../models/project";

interface IDeleteAccessFormProps {
  project?: IProject;
  stateId?: string;
  delete: (entity: any) => void;
  isOpen: boolean;
  setClosed: () => void;
}

const DeleteAccessForm: React.FC<IDeleteAccessFormProps> = (props) => {
  return (
    <Dialog
      open={props.isOpen}
      onClose={() => props.setClosed()}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
    >
      <DialogTitle
        id="dialog-title"
        sx={{ bgcolor: "#1E1E1E", color: "white" }}
      >
        Вы действительно хотите удалить это?
      </DialogTitle>
      <DialogActions sx={{ bgcolor: "#1E1E1E", pt: 2 }}>
        <Button
          variant="contained"
          onClick={() => props.setClosed()}
          sx={{ bgcolor: "gray" }}
        >
          Назад
        </Button>
        <Button
          variant="contained"
          onClick={() => props.delete(props.project ?? props.stateId)}
          sx={{ bgcolor: "error.main", color: "white" }}
        >
          Подтвердить
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteAccessForm;
