import axios from "axios";
import { IAuthResult } from "../../models/authResult";
import { domain } from "../domain";

const API_URL = "https://" + domain + "/api/1.0/auth/";

export const register = async (name: string, password: string) => {
  return await axios.post<IAuthResult>(API_URL + "register", {
    name,
    password,
  });
};

export const login = async (name: string, password: string) => {
  const response = await axios
        .post<IAuthResult>(API_URL + "login", {
            name,
            password,
        });
    if (response.data.token) {
        localStorage.setItem("user", JSON.stringify(response.data));
    }
    return response.data;
};

export const logout = () => {
  localStorage.removeItem("user");
};

export const getCurrentUser = () => {
  const userStr = localStorage.getItem("user");
  if (userStr) {
    const authResult: IAuthResult = JSON.parse(userStr);
    return authResult.user;
  }
  return null;
};
