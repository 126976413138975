import { Box, IconButton, Stack, Typography } from "@mui/material";
import { IProject } from "../../models/project";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { deleteProject } from "../../services/project-service";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import DeleteAccessForm from "../DeleteAccessForm";

interface ProjectProps {
  project: IProject;
  delete: (project: IProject) => void;
}

const ProjectItem: React.FC<ProjectProps> = (props) => {
  const [accessDeleteForm, setAccessDeleteForm] = useState(false);
  const date = new Date(props.project.createDate);

  const navigate = useNavigate();

  const handleDeleteBtn = (project: IProject) => {
    deleteProject(project.id);
    props.delete(project);
  };

  const setClosedAccessForm = () => {
    setAccessDeleteForm(false);
  };

  return (
    <Box
      component="div"
      sx={{
        border: "1px solid gray",
        borderRadius: "12px",
        py: 1,
        mt: 2,
        boxShadow: "4",
        bgcolor: "info.main",
      }}
    >
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box component="div">
          <Typography variant="h6" sx={{ ml: 2, fontSize: "24px" }}>
            {props.project.name}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              ml: 2,
              color: "#515453",
              display: "inline-block",
              fontWeight: "bold",
              fontSize: "17px",
              mt: 1,
            }}
          >
            Участники:
          </Typography>
          {props.project.partners.map((partner) => (
            <Box
              component="p"
              key={partner.id}
              sx={{
                ml: 2,
                color: "secondary.main",
                my: 0,
                display: "inline-block",
                fontSize: "17px",
                fontWeight: "bold",
              }}
            >
              {partner.name}
            </Box>
          ))}
          <Typography
            variant="body2"
            sx={{ ml: 2, color: "#515453", fontSize: "16px", mt: 1 }}
          >
            {
              date.toLocaleString()
            }
          </Typography>
        </Box>
        <Box component="div">
          <IconButton
            size="large"
            aria-label="open"
            sx={{ color: "dark" }}
            onClick={() => navigate(`/projects/${props.project.id}`)}
          >
            <VisibilityIcon fontSize="large" />
          </IconButton>
          <IconButton
            size="large"
            aria-label="delete"
            sx={{ color: "error.main" }}
            onClick={() => setAccessDeleteForm(true)}
          >
            <DeleteIcon fontSize="large" />
          </IconButton>
        </Box>
      </Stack>
      {accessDeleteForm && (
        <DeleteAccessForm
          delete={handleDeleteBtn}
          project={props.project}
          isOpen={accessDeleteForm}
          setClosed={setClosedAccessForm}
        />
      )}
    </Box>
  );
};
export default ProjectItem;
