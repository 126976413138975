import { Route, Routes } from "react-router-dom";
import { createContext, useEffect, useMemo, useState } from "react";

import LoginPage from "./pages/LoginPage";
import MainPage from "./pages/MainPage";
import ProjectPage from "./pages/ProjectPage";
import { Box, createTheme, ThemeProvider } from "@mui/material";
import Layout from "./components/layout/Layout";
import SettingsPage from "./pages/SettingsPage";
import NotFoundPage from "./pages/NotFoundPage";
import AdminPage from "./pages/AdminPage";
import RequireAuth from "./hoc/RequireAuth";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { ModalState } from "./context/ModalContext";

export const ColorModeContext = createContext({ toggleColorMode: () => {}});

function App() {
  const [mode, setMode] = useState<"light" | "dark">("light");
  
  useEffect(() => {
    if (localStorage.getItem("mode")) {
      setMode(localStorage.getItem("mode") === "light" ? "light" : "dark");
    }
  }, []);
  
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        localStorage.setItem("mode", mode === "light" ? "dark" : "light");
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
      mode,
    }),
    [mode]
  );

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          ...(mode === "light"
            ? {
                primary: {
                  main: "#f68e20",
                },
                secondary: {
                  main: "#4F4891",
                },
                error: {
                  main: "#CB0113",
                },
                info: {
                  main: "#EEEEEE",
                },
                background: {
                  default: "#fff",
                },
              }
            : {
                primary: {
                  main: "#f68e20",
                },
                secondary: {
                  main: "#4F4891",
                },
                error: {
                  main: "#CB0113",
                },
                info: {
                  main: "#272727",
                },
                background: {
                  default: "#333333",
                },
                text: {
                  primary: "#fff"
                }
              }),
        },
      }),
    [mode]
  );

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <ModalState>
          <Box
            component="div"
            sx={{
              display: "flex",
              flexDirection: "column",
              minHeight: "100vh",
            }}
          >
            <Routes>
              <Route path="/auth/login" element={<LoginPage />} />
              <Route path="/" element={<Layout />}>
                <Route
                  index
                  element={
                    <RequireAuth>
                      <MainPage />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/settings"
                  element={
                    <RequireAuth>
                      <SettingsPage mode={mode}/>
                    </RequireAuth>
                  }
                />
                <Route
                  path="/admin"
                  element={
                    <RequireAuth isAdmin={true}>
                      <AdminPage />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/projects/:projectId"
                  element={
                    <RequireAuth>
                      <ProjectPage />
                    </RequireAuth>
                  }
                />
                <Route
                  path="*"
                  element={
                    <RequireAuth>
                      <NotFoundPage />
                    </RequireAuth>
                  }
                />
              </Route>
            </Routes>
          </Box>
        </ModalState>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
