import { Alert, Box, Button, Container, Dialog, DialogContent, Snackbar, Stack, TextField, Typography } from '@mui/material'
import UserList from '../components/admin/UserList'
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import RegisterUser from '../components/admin/RegisterUser';
import Modal from '../components/Modal';
import { useContext, useState } from 'react';
import { ModalContext } from '../context/ModalContext';
import DeleteUser from '../components/admin/DeleteUser';
import { useUsers } from '../hooks/useUsers';
import { IUser } from '../models/user';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { giveUserAccess } from '../services/admin-service';

interface IUserAccess {
    userName: string;
    projectName: string;
}


export default function AdminPage() {
    const { open } = useContext(ModalContext)
    const [deleteForm, setDeleteForm] = useState(false);
    const { users, addUser, deleteUser } = useUsers();
    const [openAlert, setOpenAlert] = useState(false);

    const createUserHandler = (user: IUser) => {
        addUser(user)
    }

    const deleteUserHandler = (name: string) => {
        deleteUser(name)
    }

    const { handleSubmit, control } = useForm<IUserAccess>({
        mode: "onChange"
    });

    const onSubmit: SubmitHandler<IUserAccess> = async (data) => {
        await giveUserAccess(data.userName, data.projectName)
        handleClick()
    }

    const handleClick = () => {
        setOpenAlert(true);
    };

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenAlert(false);
    };

    return (
        <Container sx={{ mt: 4 }}>
            <Stack direction='row' spacing={1} justifyContent='space-between'>
                <form style={{ marginTop: '90px', padding: '60px' }} onSubmit={handleSubmit(onSubmit)}>
                    <Box component='div' sx={{ py: 4 }}>
                        <Typography variant='h6' sx={{ color: 'gray' }}>Доступы к проекту</Typography>
                        <Controller
                            control={control}
                            name="userName"
                            render={({ field }) => (
                                <TextField
                                    label="Имя пользователя"
                                    fullWidth
                                    sx={{ mt: 2 }}
                                    variant="outlined"
                                    onChange={(e) => field.onChange(e)}
                                    value={field.value}
                                />)}
                        />
                        <Controller
                            control={control}
                            name="projectName"
                            render={({ field }) => (
                                <TextField
                                    label="Название проекта"
                                    fullWidth
                                    sx={{ mt: 2 }}
                                    variant="outlined"
                                    onChange={(e) => field.onChange(e)}
                                    value={field.value}
                                />)}
                        />
                    </Box>
                    <Button type='submit' variant='contained' sx={{ mt: 2 }}>Выдать доступ</Button>
                </form>
                <Stack direction='column' spacing={2} sx={{ py: 4, width: '75%' }}>
                    <Stack direction='row' spacing={2} justifyContent='space-between'>
                        <div>
                            <Button
                                startIcon={<AddIcon />}
                                variant='contained'
                                size='small'
                                sx={{ bgcolor: 'secondary.main', mr: 3, color: 'white', height: '40px', fontSize: '10px' }}
                                onClick={() => open()}>Добавить</Button>
                            <Button
                                startIcon={<DeleteIcon />}
                                variant='contained'
                                size='small'
                                sx={{ bgcolor: 'error.main', color: 'white', height: '40px', fontSize: '10px' }}
                                onClick={() => setDeleteForm(true)}>Удалить</Button>
                        </div>
                        <Typography variant='h4' sx={{ fontWeight: '500' }}>Список пользователей</Typography>
                    </Stack>
                    <UserList users={users} />
                </Stack>
            </Stack>
            <Modal title="">
                <RegisterUser onCreate={createUserHandler} />
            </Modal>
            <Dialog
                open={deleteForm}
                onClose={() => setDeleteForm(false)}
                aria-labelledby='dialog-title'
                aria-describedby='dialog-description'>
                <DialogContent>
                    <DeleteUser onDelete={deleteUserHandler} close={() => setDeleteForm(false)} />
                </DialogContent>
            </Dialog>
            <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Доступ к проекту выдан!
                </Alert>
            </Snackbar>
        </Container>

    )
}
