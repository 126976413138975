import { Box, Typography, TextField, InputAdornment, Button, Grid } from "@mui/material";
import { useContext } from "react";
import { useForm, useFormState, SubmitHandler, Controller } from "react-hook-form";
import { register } from "../../services/auth-service/auth-service";
import { yupResolver } from "@hookform/resolvers/yup";
import { registerValidationSchema } from "../../services/rules/register-validation-schema";
import { ModalContext } from "../../context/ModalContext";
import { IUserCredentials } from "../../models/userCredentials";
import { IUser } from "../../models/user";

interface IRegisterUserProps {
    onCreate: (user: IUser) => void
}

const RegisterUser: React.FC<IRegisterUserProps> = (props) => {
    const { close } = useContext(ModalContext)

    const { handleSubmit, control, setValue } = useForm<IUserCredentials>({
        mode: "onChange",
        resolver: yupResolver(registerValidationSchema)
    });

    const { errors } = useFormState({
        control
    });

    const onSubmit: SubmitHandler<IUserCredentials> = async (data) => {
        const response = await register(data.name, data.password )
        props.onCreate(response.data.user)
    }

    function genPassword() {
        var chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
        var passwordLength = 12;
        var password = "";
        for (var i = 0; i <= passwordLength; i++) {
            var randomNumber = Math.floor(Math.random() * chars.length);
            password += chars.substring(randomNumber, randomNumber + 1);
        }
        console.log(password);
        setValue('password', password)
    }

    return (
        <Grid container>

            <Box
                component='div'
                sx={{
                    p: '20px',               
                }}
            >
                <Typography variant="h4" component="div">
                    Добавление
                </Typography>
                <Typography
                    variant="subtitle1"
                    component="div"
                    gutterBottom={true}
                    sx={{
                        color: '#b4b8c1'
                    }}
                >
                    Нового пользователя
                </Typography>
                <form style={{ maxWidth: '360px' }} onSubmit={handleSubmit(onSubmit)}>
                    <Controller
                        control={control}
                        name="name"
                        render={({ field }) => (
                            <TextField
                                label="Имя"
                                size="small"
                                margin="normal"                                                       
                                fullWidth={true}
                                onChange={(e) => field.onChange(e)}
                                value={field.value}
                                error={!!errors.name?.message}
                                helperText={errors.name?.message}
                                InputLabelProps={{ shrink: true }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="password"
                        render={({ field }) => (
                            <TextField
                                label="Пароль"
                                type="text"
                                size="small"
                                margin="normal"                                                                                     
                                fullWidth={true}
                                onChange={(e) => field.onChange(e)}
                                value={field.value}
                                error={!!errors.password?.message}
                                helperText={errors.password?.message}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Button size="small" onClick={() => genPassword()} sx={{ color: 'black', bgcolor: 'lightgray', ":hover": { bgcolor: 'gray' } }}>
                                                сгенерировать
                                            </Button>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        )}
                    />
                    <Button
                        type="submit"
                        onClick={() => close()}
                        variant="contained"
                        disableElevation={true}
                        sx={{
                            marginTop: 2,
                            width: '50%'
                        }}
                        color='secondary'
                    >
                        Подтвердить
                    </Button>
                    <Button
                        onClick={() => close()}
                        variant="contained"
                        disableElevation={true}
                        sx={{
                            mt: 2,
                            ml: 4,
                            width: '40%',
                            color: 'white',
                            bgcolor: 'error.main'
                        }}
                    >
                        Назад
                    </Button>
                </form>
            </Box>
        </Grid>
    )
}
export default RegisterUser;