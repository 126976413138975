import { Box, Container, Divider, Stack, Switch, Typography } from "@mui/material";
import { useContext } from "react";
import { ColorModeContext } from "../App";

interface ISettingsPageProps {
  mode: "light" | "dark"
}

export default function SettingsPage({mode}: ISettingsPageProps) {
  const colorMode = useContext(ColorModeContext);
  
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    colorMode.toggleColorMode()
  };
  
  return (
    <Box sx={{ textAlign: "left", fontSize: "25px", marginTop: "20px" }}>
      <Container>
        <Typography variant="h4" sx={{mt:2}}>Настройки</Typography>
        <Divider/>
        <Stack direction={'row'} sx={{mt:3}} alignItems="center">
          <Typography color='secondary' variant="subtitle1" fontWeight='bold'>Темная тема</Typography>
          <Switch checked={mode === 'dark' ? true : false} onChange={handleChange}></Switch>
        </Stack>
      </Container>
    </Box>
  );
}
