import { AppBar, Box, Button, CssBaseline, Drawer, IconButton, Toolbar, Typography } from "@mui/material";
import { useState } from "react";
import { getCurrentUser, logout } from "../../services/auth-service/auth-service";
import { useNavigate } from "react-router-dom";
import SideList from "./SideList";
import { Menu } from "@mui/icons-material";

const NavBar: React.FC = () => {

    const navigate = useNavigate();
    const goOut = () => {
        logout()
        navigate('auth/login', { replace: true })
    }

    const goTo = (path: string) => {
        navigate(path)
        toggleSlider()
    }

    const user = getCurrentUser();

    const [open, setOpen] = useState(false);

    const toggleSlider = () => {
        setOpen(!open);
    };

    return (
        <>
            <CssBaseline />

            <Box component="nav" sx={{}}>
                <AppBar position="static">
                    <Toolbar sx={{ color: 'white' }}>
                        <IconButton onClick={toggleSlider} color='inherit'>
                            <Menu />
                        </IconButton>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                            Omega Remote Config
                        </Typography>
                        <Drawer open={open} anchor="left" onClose={toggleSlider}>
                            <SideList goTo={goTo}/>
                        </Drawer>
                        {user ? (
                            <Button color="inherit" onClick={goOut}>Выйти</Button>
                        ) : (
                            <Button onClick={() => navigate('auth/login', { replace: true })} color="inherit">Войти</Button>
                        )}
                    </Toolbar>
                </AppBar>
            </Box>
        </>
    );
};
export default NavBar;

