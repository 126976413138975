import axios from "axios";
import { IUser } from "../models/user";
import authHeader from "./auth-service/auth-header";
import { domain } from "./domain";

const API_URL = "https://" + domain + "/api/1.0/admin";

export const getAllUsers = async () => {
  return await axios.get<IUser[]>(API_URL + "/users", { headers: authHeader() });
};

export const deleteUser = async (name: string) => {
  return await axios.delete(API_URL + `/users/${name}`, { headers: authHeader() });
};

export const giveUserAccess = async (userName: string, projectName: string) => {
  return await axios.post(API_URL + `/access`, {userName, projectName}, { headers: authHeader() });
};