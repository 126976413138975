export default function authHeader() {
  const userStr = localStorage.getItem("user");
  let user = null;
  if (userStr)
    user = JSON.parse(userStr);

  if (user && user.token)
    return { Authorization: 'Bearer ' + user.token };
  else
    return { Authorization: '' };
}

export function authFormHeader() {
  const userStr = localStorage.getItem("user");
  let user = null;
  if (userStr)
    user = JSON.parse(userStr);

  if (user && user.token)
    return {
      Authorization: 'Bearer ' + user.token,
      'Content-Type': 'multipart/form-data',
      'Accept': 'application/json'
    };
  else
    return {
      Authorization: '',
      'Content-Type': 'multipart/form-data',
      'Accept': 'application/json'
    };
}