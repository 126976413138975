import { yupResolver } from '@hookform/resolvers/yup';
import { Box, TextField, Button } from '@mui/material';
import { useForm, useFormState, SubmitHandler, Controller } from 'react-hook-form';
import { IState } from '../../models/state';
import { createState } from '../../services/state-service';
import { stateValidationSchema } from '../../services/rules/state-validation-schema';

interface ICreateStateProps {
    name?: string;
    projectId?: string;
    onCreate: (state: IState) => void;
    close: () => void;
}

const CreateState: React.FC<ICreateStateProps> = (props) => {

    const { handleSubmit, control } = useForm<ICreateStateProps>({
        mode: "onChange",
        resolver: yupResolver(stateValidationSchema),

    });

    const { errors } = useFormState({
        control
    });

    const onSubmit: SubmitHandler<ICreateStateProps> = async (data) => {
        const response = await createState(props.projectId!, data.name!)
        props.onCreate(response.data)
    }

    return (
        <Box
            component='div'
            sx={{
                p: '20px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <form style={{ maxWidth: '360px' }} onSubmit={handleSubmit(onSubmit)}>
                <Controller
                    control={control}
                    name="name"
                    render={({ field }) => (
                        <TextField
                            label="Название"
                            size="small"
                            margin="normal"
                            className="auth-form_input"
                            fullWidth={true}
                            onChange={(e) => field.onChange(e)}
                            value={field.value}
                            error={!!errors.name?.message}
                            helperText={errors.name?.message}
                        />
                    )}
                />
                <Button
                    onClick={props.close}
                    variant="contained"
                    disableElevation={true}
                    sx={{
                        mt: 5,
                        width: '40%',
                        bgcolor: 'error.main',
                        color: 'white'
                    }}
                >
                    Назад
                </Button>
                <Button
                    variant="contained"
                    type='submit'
                    onClick={props.close}
                    disableElevation={true}
                    sx={{
                        mt: 5,
                        ml: 4,
                        width: '50%',
                        bgcolor: 'secondary.main',
                        color: 'white'
                    }}
                >
                    Сохранить
                </Button>
            </form>
        </Box>
    )
}
export default CreateState;