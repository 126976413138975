import { Dialog, DialogTitle, DialogContent, IconButton } from "@mui/material"
import { useContext } from "react"
import { ModalContext } from "../context/ModalContext"
import CloseIcon from '@mui/icons-material/Close';

interface ModalProps {
    children: React.ReactNode
    title: string
    bgColor?: string
    contentColor?: string
    titleColor?: string
    titleTextAlign?: any
    closeColor?: string
    fullScreen?: boolean
}

const Modal: React.FC<ModalProps> = (props) => {
    const { modal, close } = useContext(ModalContext)

    return (
        <>
            <Dialog
                open={modal}
                onClose={close}
                aria-labelledby='dialog-title'
                aria-describedby='dialog-description'
                maxWidth="lg"
                fullScreen={props.fullScreen}>
                <DialogTitle id='dialog-title' sx={{ bgcolor: props.bgColor, color: props.titleColor, textAlign: props.titleTextAlign }}>
                    {props.title}

                    <IconButton
                        aria-label="close"
                        onClick={close}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: props.closeColor,
                        }}
                    >
                        <CloseIcon fontSize="large"/>
                    </IconButton>

                </DialogTitle>
                <DialogContent sx={{ bgcolor: props.bgColor, color: props.contentColor, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    {props.children}
                </DialogContent>
            </Dialog>
        </>
    );
}
export default Modal;