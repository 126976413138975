import { Box } from '@mui/material'
import Login from '../components/auth/Login'
import bgYellow from '../images/yellow-abstract.png'

export default function LoginPage() {
  return (
    <Box component='div' sx={{height: '100vh', backgroundImage: `url(${bgYellow})`, backgroundSize: 'cover'}}>
        <Login/>
    </Box>
  )
}
