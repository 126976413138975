import { Box } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { IUser } from '../../models/user';

const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 250 },
    { field: 'name', headerName: 'Name', width: 280 },
    { field: 'role', headerName: 'Role', width: 100 },
];

interface IUserListProps {
    users: IUser[]
}

const UserList: React.FC<IUserListProps> = (props) => {

    return (
        <Box component='div' sx={{ height: 500, width: '100%' }}>
            <DataGrid
                rows={props.users}
                columns={columns}
                pageSize={7}
                rowsPerPageOptions={[5]}
            />
        </Box>
    )
}
export default UserList;