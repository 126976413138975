import axios from "axios";
import { IState } from "../models/state";

import authHeader, { authFormHeader } from "./auth-service/auth-header";
import { domain } from "./domain";

const API_URL = "https://" + domain + "/api/1.0/configurations/"

export const createState = async (projectId: string, name: string) => {
    return await axios.post<IState>(API_URL, {
        name, projectId
    }, { headers: authHeader() });
};

export const deleteState = async (id: string) => {
    return await axios.delete(API_URL + `${id}`, {
        headers: authHeader()
    });
};

export const getStatesByProject = async (projectId: string) => {
    return await axios.get(API_URL, { headers: authHeader(), params: {projectId: projectId} });
};

//--------------------------Configurations----------------------------//

export const createConfig = async (formData: FormData, stateId: string) => {
    return await axios.post(API_URL + `${stateId}/configuration`,
        formData,
        {
            headers: authFormHeader()
        });
};

export const updateConfig = async (formData: FormData, stateId: string) => {
    return await axios.put(API_URL + `${stateId}/configuration`,
        formData,
        {
            headers: authFormHeader()
        });
};