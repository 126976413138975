import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Button,
  Box,
} from "@mui/material";
import { useState } from "react";
import {
  useForm,
  useFormState,
  SubmitHandler,
  Controller,
} from "react-hook-form";
import { login } from "../../services/auth-service/auth-service";
import { loginValidationSchema } from "../../services/rules/login-validation-schema";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { IUserCredentials } from "../../models/userCredentials";
import { IError } from "../../models/error";

interface ILoginProps {}

const Login: React.FC<ILoginProps> = (props) => {
  const [error, setError] = useState<IError | null>(null);

  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const { handleSubmit, control } = useForm<IUserCredentials>({
    mode: "onChange",
    resolver: yupResolver(loginValidationSchema),
  });

  const { errors } = useFormState({
    control,
  });

  const onSubmit: SubmitHandler<IUserCredentials> = async (data) => {
    await login(data.name, data.password)
      .then(() => goHome())
      .catch((error) => {
        setError(error.response.data);
      });
  };

  const goHome = () => navigate("/", { replace: true });

  return (
    <Box
      component="div"
      sx={{
        width: "50vw",
        height: "100vh",
        p: "20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        bgcolor: 'background.default',
        color: 'text.primary'
      }}
    >
      <Typography variant="h4">
        Войдите
      </Typography>
      <Typography
        variant="subtitle1"
        gutterBottom={true}
        sx={{
          color: "#b4b8c1",
        }}
      >
        Чтобы получить доступ
      </Typography>
      <form style={{ maxWidth: "360px" }} onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="name"
          render={({ field }) => (
            <TextField
              label="Имя"
              size="small"
              margin="normal"
              className="auth-form_input"
              fullWidth={true}
              onChange={(e) => field.onChange(e)}
              value={field.value}
              error={!!errors.name?.message}
              helperText={errors.name?.message}
            />
          )}
        />
        <Controller
          control={control}
          name="password"
          render={({ field }) => (
            <TextField
              label="Пароль"
              type={showPassword ? "text" : "password"}
              size="small"
              margin="normal"
              className="auth-form_input"
              fullWidth={true}
              onChange={(e) => field.onChange(e)}
              value={field.value}
              error={!!errors.password?.message}
              helperText={errors.password?.message}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        <Typography variant="body2" color="error" sx={{ ml: 2 }}>
          {error && "Неверные данные для входа"}
        </Typography>
        <Button
          type="submit"
          variant="contained"
          disableElevation={true}
          sx={{
            marginTop: 2,
            width: "40%",
          }}
          color="secondary"
        >
          Войти
        </Button>
      </form>
    </Box>
  );
};
export default Login;
