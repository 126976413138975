import {useEffect, useState} from 'react'
import {IProject} from '../models/project'
import { getCurrentUser } from '../services/auth-service/auth-service'
import { getProjects } from '../services/project-service'

export function useProjects() {
  const [projects, setProjects] = useState<IProject[]>([])
  
  function addProject(project: IProject) {
    setProjects(prev => [...prev, project])
  }

  function deleteProject(project: IProject) {
    setProjects(projects.filter(p => p.id !== project.id))
  }

  async function fetchProjects() {
    const user = getCurrentUser()
    
    try {   
      const response = await getProjects(user?.id)
      setProjects(response.data)  
    } catch (e: unknown) {
      //const error = e as AxiosError
    }
  }

  useEffect(() => {
    fetchProjects()
  }, [])

  return { projects, addProject, deleteProject }
}