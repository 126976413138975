import {useEffect, useState} from 'react'
import { IUser } from '../models/user'
import { getAllUsers } from '../services/admin-service'

export function useUsers() {
  const [users, setUsers] = useState<IUser[]>([])
  
  function addUser(user: IUser) {
    setUsers(prev => [...prev, user])
  }

  function deleteUser(name?: string) {
    setUsers(users.filter(p => p.name !== name))
  }

  async function fetchUsers() {
    try {   
      const response = await getAllUsers()
      setUsers(response.data)  
    } catch (e: unknown) {
      //const error = e as AxiosError
    }
  }

  useEffect(() => {
    fetchUsers()
  }, [])

  return { users, addUser, deleteUser }
}