import { yupResolver } from '@hookform/resolvers/yup';
import { Box, TextField, Button } from '@mui/material';
import { useContext } from 'react';
import { useForm, useFormState, SubmitHandler, Controller } from 'react-hook-form';
import { ModalContext } from '../../context/ModalContext';
import { IProject } from '../../models/project';
import { getCurrentUser } from '../../services/auth-service/auth-service';
import { addProject } from '../../services/project-service';
import { projectValidationSchema } from '../../services/rules/project-validation-schema';

interface ICreateProjectProps {
  name?: string;
  onCreate: (project : IProject) => void
}

const CreateProject: React.FC<ICreateProjectProps> = (props) => {
  const {close} = useContext(ModalContext)
  
  const {handleSubmit, control} = useForm<ICreateProjectProps>({
    mode: "onChange",
    resolver: yupResolver(projectValidationSchema),
});

const { errors } = useFormState({
    control
});

const onSubmit: SubmitHandler<ICreateProjectProps> = async (data) => {
    const user = getCurrentUser()
    const response = await addProject(data.name, user?.id)
    props.onCreate(response.data)
}
    
return (
    <Box 
        component='div' 
        sx={{         
            p: '20px', 
            display: 'flex', 
            flexDirection: 'column', 
            justifyContent: 'center', 
            alignItems: 'center'}}
    >       
        <form style={{maxWidth: '360px'}} onSubmit={handleSubmit(onSubmit)}>
            <Controller
                control={control}
                name="name"                    
                render={({ field }) => (
                    <TextField
                        label="Название"
                        size="small"
                        margin="normal"
                        className="auth-form_input"
                        fullWidth={true}
                        onChange={(e) => field.onChange(e)}
                        value={field.value}
                        error={!!errors.name?.message}
                        helperText={errors.name?.message}
                    />
                )}
            />
            <Button                          
                variant="contained"
                disableElevation={true}
                onClick={close}
                sx={{
                    mt: 5,
                    width: '40%',
                    bgcolor: 'error.main', 
                    color: 'white'
                }}
            >
                Назад
            </Button>
            <Button               
                variant="contained"
                type='submit'
                onClick={close}
                disableElevation={true}
                sx={{
                    mt: 5,
                    ml: 4,
                    width: '50%',
                    bgcolor: 'secondary.main',
                    color: 'white'
                }}
            >
                Сохранить
            </Button>
        </form>
    </Box>
)
}
export default CreateProject;